import { createAction, props } from '@ngrx/store';

import { AccommodationLookup, StatsRequest, StatsResponse } from '../../models';

export const loadRequest = createAction(
  '[Production Accommodations] Load Request',
  props<{ data: Partial<StatsRequest> }>(),
);
export const loadSuccess = createAction(
  '[Production Accommodations] Load Success',
  props<{ data: StatsResponse }>(),
);
export const loadFailure = createAction(
  '[Production Accommodations] Load Failure',
  props<{ error: any }>(),
);

export const loadAccommodationsSuccess = createAction(
  '[Production Accommodations] Load Accommodation Success',
  props<{
    accommodations: AccommodationLookup;
    statsRequest: Partial<StatsRequest>;
  }>(),
);

export const resetState = createAction(
  '[Production Accommodations] Reset State',
);
