import { Action, createReducer, on } from '@ngrx/store';

import { buildDailyClosureProductionState } from '../../helpers';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,

  on(fromActions.loadRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { data }) => ({
    ...buildDailyClosureProductionState<fromState.State>(state, data),
    loading: false,
    error: null,
  })),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(fromActions.resetState, () => ({
    ...fromState.initialState,
  })),
);

export function productionAccommodationsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
