import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import {
  AccommodationLookup,
  StatsFilter,
  StatsRequest,
  StatsResponse,
} from '../../models';
import { AccommodationsService, StatsProductionService } from '../../services';

import * as fromActions from './actions';

@Injectable()
export class ProductionAccommodationsStoreEffects {
  constructor(
    private dataService: StatsProductionService,
    public accommodationsService: AccommodationsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ data }) => {
        return this.accommodationsService.loadLookup(data.property_ids).pipe(
          map((response: IResponseSuccess<AccommodationLookup>) => {
            return fromActions.loadAccommodationsSuccess({
              accommodations: response.data,
              statsRequest: data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        );
      }),
    ),
  );

  loadAccommodationsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadAccommodationsSuccess),
      switchMap(({ statsRequest, accommodations }) => {
        return this.dataService
          .load(this.setAccommodationFilter(accommodations, statsRequest))
          .pipe(
            map((response: IResponseSuccess<StatsResponse>) => {
              return fromActions.loadSuccess({ data: response.data });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.loadFailure(error));
            }),
          );
      }),
    ),
  );

  setAccommodationFilter(
    accommodations: AccommodationLookup,
    request: Partial<StatsRequest>,
  ): Partial<StatsRequest> {
    const filters: StatsFilter[] = [
      {
        name: 'accommodation',
        payload: Object.keys(accommodations || {}).reduce(
          (acc: number[], propertyId) => [
            ...acc,
            ...(accommodations[propertyId] || []).map(({ id }) => id),
          ],
          [],
        ),
      },
    ];

    if (!filters[0].payload.length) {
      return request;
    }

    return {
      ...request,
      filters,
    };
  }
}
