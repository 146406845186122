import { ProductionAccommodation } from '../../models';

export interface State {
  rows: ProductionAccommodation[];
  totals: Partial<ProductionAccommodation>;
  loading: boolean;
  error: any;
}

export const initialState: State = {
  rows: null,
  totals: null,
  loading: false,
  error: null,
};
